import React from "react";
import { useHistory } from "react-router-dom";
import { Divider, Card, FormControlLabel, Checkbox, List, Tabs, Tab, ListItemButton, Link, ListItem, TextField, Button, IconButton, Box, Grid, Typography } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import InputAdornment from "@mui/material/InputAdornment";
import TitleSubtitle from "./TitleSubtitle";
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { checkAuth, selectAccount } from "../../api/channel/action";
import { AdsList, AttributionList, ChannelType, GetChannel } from "../../models/channel";
import { TaskAlt } from "@mui/icons-material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RemoveChannelAccountModal from "../Modal/RemoveChannelAccountModal"
import { getUser } from "../../api/user/action";
import AddIcon from "@mui/icons-material/Add";
import ShopifyConnect from "./ShopifyConnect";
import { closeConnecting } from "../../api/shopify-ads/action";
import { sendBingCode } from "../../api/bing-ads/action";
import CloseIcon from "../Common/CloseIcon";
import { getAllReports, getDefaultReport } from "../../api/report/action";
import SelectingChannelErrorModal from "../Modal/SelectingChannelErrorModal";
import { sendLinkedlnCode } from "../../api/linkedin-ads/action";
import Loader from "../Common/Loader";
import { ReactComponent as ArrowLeftIcon } from "../../Assets/Icons/left_arrow.svg";
import { ReactComponent as ArrowRightIcon } from "../../Assets/Icons/right_arrow.svg";
import { EVENTS, transformMixPanelData } from "../../utils/mixpanel";
import AppsflyerConnect from "./AppsflyerConnect";
import { closeConnectingAppsflyer } from "../../api/appsflyer/action";
import AdjustConnect from "./AdjustConnect";
import { closeConnectingAdjust } from "../../api/adjust/action";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const mappedCompo = {
    [ChannelType.ShopifyAds]: <ShopifyConnect />,
    [ChannelType.Appsflyer]: <AppsflyerConnect />,
    [ChannelType.Adjust]: <AdjustConnect />
}
function ManageDataSources(props) {
    const query = new Map(props.location.search.replace("?", "").split("&").map(q => q.split("=")));
    const history = useHistory();
    const { allReports, defaultReportId, authenticatedAccounts, selectedAccounts, checkAuth, user, getUser, getAllReports, getDefaultReport, isConnectingShopify, closeShopify, sendBingCode, sendLinkedlnCode, isConnectingAppsflyer, closeAppsflyer, isConnectingAdjust, closeAdjust } = props;
    const [filteredAccountList, setFilteredAccountList] = React.useState([]);
    const [modal, setModal] = React.useState(false);
    const [errorModal, setErrorModal] = React.useState(query.has("failure"));
    const disconnectAccountPayload = React.useRef({});
    const selectedAccountTypes = Object.keys(selectedAccounts);

    const isChannelAuthenticated = channel => !!authenticatedAccounts.find(type => type === channel?.type);
    const isChannelAccountSelected = channel => channel.type in selectedAccounts;
    const isChannelSelected = channel => isAnyChannelSelected && channel.type === channelType;

    const dispatch = useDispatch();
    const [channelType, setChannelType] = React.useState(
        query.get("channel") && GetChannel(query.get("channel")) ? query.get("channel")
            : props.location?.state?.channelType && isChannelAuthenticated(GetChannel(props.location.state.channelType)) ? props.location.state.channelType
                : selectedAccountTypes.length > 0 ? selectedAccountTypes[0]
                    : authenticatedAccounts.length > 0 ? authenticatedAccounts[0]
                        : ChannelType.Default
    );
    const channel = GetChannel(channelType);
    const state = useSelector(state => channel.getState(state))
    const accountData = state.accountData;
    const selectedAccountData = selectedAccounts[channelType];

    const [isAnyChannelSelected, setIsAnyChannelSelected] = React.useState(true);

    const [value, setValue] = React.useState(AttributionList.find(c => c.type === channelType) ? 1 : 0);

    React.useEffect(() => {
        checkAuth();
        getUser();
        getAllReports();
        closeShopify();
        getBingCode();
        getLinkedlnCode();
        getDefaultReport({ loading: true });
        closeAppsflyer();
        closeAdjust();
    }, []);


    React.useEffect(() => {
        setPage(0);
        setFilteredAccountList(accountData.accountList ?? []);
    }, [accountData]);

    const getBingCode = () => {
        const splitted = window.location.href.split("code=");
        if (splitted.length === 2 && splitted[1].endsWith("&state=12345")) {
            const code = splitted[1].split("&state=12345")[0];
            sendBingCode({
                redirect_url: "managesources",
                code: code,
            });
        };
    }

    const getLinkedlnCode = () => {
        const splitted = window.location.href.split("code=");
        if (splitted.length === 2 && splitted[1].endsWith("&state=foobar")) {
            const code = splitted[1].split("&state=foobar")[0];
            sendLinkedlnCode({
                redirect_url: "managesources",
                code: code
            });
        }
    }

    const getAccountId = item => Object.values(channel.transformer.getAccountId(item)).join("|");

    const totalAuthenticatedAds = AdsList.filter(channel => isChannelAuthenticated(channel)).length;
    const totalAuthenticatedAttributes = AttributionList.filter(channel => isChannelAuthenticated(channel)).length;

    const handleChange = (event, newValue) => {
        setIsAnyChannelSelected(false);
        setValue(newValue);
    };

    const handleCheckboxSelection = item => {
        dispatch(selectAccount({ channel: channelType, account: item }));
        dispatch(channel.actions.getCurrency(item));
        dispatch(channel.actions.getFields);
    };

    const changeChannel = channel => {
        if (channel.isSupported) {
            setIsAnyChannelSelected(true);
            setChannelType(channel.type);
            if (isConnectingShopify) { closeShopify(); }
            if (isConnectingAppsflyer) { closeAppsflyer(); }
            if (isConnectingAdjust) { closeAdjust(); }
        }
    }

    function fetchFilteredList(searchText) {
        setPage(0);
        setFilteredAccountList(accountData.accountList.filter(
            item => {
                return (
                    item
                        .title
                        .toLowerCase()
                        .includes(searchText.toLowerCase()) ||
                    item
                        .subtitle
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                );
            }
        ))
    }

    const isShopifyChannel = channelType === ChannelType.ShopifyAds;
    const isAppsflyerChannel = channelType === ChannelType.Appsflyer;
    const isAdjustChannel = channelType === ChannelType.Adjust;

    const [page, setPage] = React.useState(0);
    const rowsPerPage = 20;
    return (
        <div style={{ padding: "24px", backgroundColor: "#ffff", minHeight: "100vh" }}>
            {props.location?.state?.prevPath === "/selectchannels" &&
                <Button style={{ border: "1px solid black", color: "black", padding: "5px 15px" }} onClick={() => history.goBack()}>
                    <ArrowBackIosIcon style={{ width: "20px", height: "10px" }} /> Back
                </Button>
            }
            {
                user?.role === 'externaleditor' && <CloseIcon />
            }
            <Card sx={{ boxShadow: 3, mb: 2, marginTop: 3, variant: "outlined" }} >
                <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                    style={{ padding: 20 }}>
                    <Grid item sm={12} lg={12}>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="space-around" alignItems="stretch">
                            <Grid item xs={8} align="left">
                                <Typography variant="h5">
                                    Manage data sources
                                </Typography>
                                <Typography variant="subtitle1">
                                    Total sources used
                                </Typography>
                            </Grid>
                            <Grid style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '10px' }} item xs={4}>
                                <Grid
                                    container
                                    spacing={2}
                                    justifyContent="center" alignItems="center">
                                    <Grid item xs={12} align="right">
                                        {authenticatedAccounts.map((channelType, index) => {
                                            const channel = GetChannel(channelType);
                                            return <img key={index} style={{ marginRight: 8, height: 30, width: 30 }} src={channel.icon} alt={channel.title} />
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={6}>
                                <Typography variant="h6">
                                    {authenticatedAccounts.length}
                                </Typography>
                            </Grid>
                            <Grid xs={6}>

                                <Typography style={{ color: '#999999', textAlign: 'right' }} variant="subtitle1">
                                    Connected Data Sources
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} align="right">
                        <Divider sx={{ borderBottomWidth: 6, background: "black" }} />
                    </Grid>
                </Grid>
            </Card>
            <Grid container spacing={2} >
                <Grid item xs={3} style={{ height: "calc(100vh - 305px)" }}>
                    <Card sx={{ boxShadow: 3 }} style={{ height: "100%" }}>
                        <Tabs style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} variant="fullWidth" value={value} onChange={handleChange} TabIndicatorProps={{ style: { background: "#0869FB" } }} >
                            <Tab sx={{ textTransform: 'none' }} label={`Ads Channels (${totalAuthenticatedAds})`} />
                            <Tab sx={{ textTransform: 'none' }} label={`Attribution (${totalAuthenticatedAttributes})`} />
                        </Tabs>
                        <TabPanel value={value} index={0} style={{ overflowY: "auto", height: "calc(100% - 60px)" }}>
                            <Stack spacing={0}>
                                {AdsList.map((channel, index) =>
                                    <ListItem key={index} divider={true} disabled={!channel.isSupported} onClick={() => changeChannel(channel)} sx={!channel.isSupported ? { cursor: 'default' } : isChannelSelected(channel) ? { cursor: 'pointer', background: '#E6F0FF', borderBottom: '1px solid rgba(0,0,0,0.04)' } : { cursor: 'pointer', borderBottom: '1px solid rgba(0,0,0,0.04)' }}>
                                        <div style={{ display: 'flex', width: '100%', padding: 10, alignItems: 'center' }}>
                                            <div>
                                                <img style={{ marginRight: 16, height: 20, width: 20 }} src={channel.icon} alt={channel.title} /></div>
                                            <div>
                                                <Typography variant="body1">
                                                    {channel.title}
                                                </Typography>
                                            </div>
                                            {isChannelAuthenticated(channel) &&
                                                <div style={{ marginLeft: 'auto', display: 'flex' }}>
                                                    <TaskAlt sx={isChannelAccountSelected(channel) ? { color: 'green' } : { color: 'black' }} />
                                                </div>
                                            }
                                        </div>
                                    </ListItem>
                                )}
                            </Stack>
                        </TabPanel>
                        <TabPanel value={value} index={1} style={{ overflowY: "auto", height: "calc(100% - 60px)" }}>
                            {AttributionList.map((channel, index) =>
                                <ListItem key={index} divider={true} disabled={!channel.isSupported} onClick={() => changeChannel(channel)} sx={!channel.isSupported ? { cursor: 'default' } : isChannelSelected(channel) ? { cursor: 'pointer', background: '#E6F0FF', borderBottom: '1px solid rgba(0, 0, 0, 0.04)' } : { cursor: 'pointer', borderBottom: '1px solid rgba(0,0,0,0.04)' }} >
                                    <div style={{ display: 'flex', width: '100%', padding: 10, alignItems: 'center' }}>
                                        <div>
                                            <img style={{ marginRight: 16, height: 20, width: 20 }} src={channel.icon} alt={channel.title} />
                                        </div>
                                        <div>
                                            <Typography variant="body1">
                                                {channel.title}
                                            </Typography>
                                        </div>
                                        {isChannelAuthenticated(channel) &&
                                            <div style={{ marginLeft: 'auto', display: 'flex' }}>
                                                <TaskAlt sx={isChannelAccountSelected(channel) ? { color: 'green' } : { color: 'black' }} />
                                            </div>
                                        }
                                    </div>
                                </ListItem>
                            )}
                        </TabPanel>
                    </Card>
                </Grid>
                <Grid item xs={3} style={{ height: "calc(100vh - 305px)" }}>
                    <Card sx={{ boxShadow: 3 }} style={{ height: "100%", overflowY: "auto" }}>
                        <TextField
                            placeholder='Accounts'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        <SearchIcon sx={{ marginLeft: '15px' }} />
                                    </InputAdornment>
                                ),
                            }}
                            variant='standard'
                            sx={{ width: '100%', ".MuiInput-root": { height: '48px' }, ".MuiInput-root:before": { borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }, ".MuiInput-input": { marginLeft: '15px', marginTop: '2px' } }}
                        />

                        <Stack spacing={0} style={{ position: "relative" }}>
                            {isAnyChannelSelected ?
                                ((isShopifyChannel && isConnectingShopify) || (isAppsflyerChannel && isConnectingAppsflyer) || (isAdjustChannel && isConnectingAdjust)) ? mappedCompo[channel.type]
                                    : (isChannelAuthenticated(channel) ?
                                        <div>
                                            {accountData.users?.map((accountUser, index) =>
                                                <ListItem key={index} divider={true}>
                                                    <div style={{ width: "100%", textAlign: "left" }}>
                                                        <div>
                                                            <Typography variant="subtitle2">
                                                                {accountUser.username}
                                                            </Typography>
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Typography style={{ fontSize: 11 }}>
                                                                {accountUser.email}
                                                            </Typography>
                                                            {(user.role === "admin" || user.role === "superadmin") &&
                                                                <Button
                                                                    style={{ fontSize: 11, color: "red", marginLeft: "auto", marginTop: -10 }}
                                                                    onClick={() => {
                                                                        setModal(true);
                                                                        if (isShopifyChannel) {
                                                                            disconnectAccountPayload.current = {
                                                                                store_name: accountUser.storeName,
                                                                            }
                                                                        }
                                                                    }}
                                                                >REMOVE</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </ListItem>)}
                                            {isShopifyChannel && (user.role === "admin" || user.role === "superadmin") && <div style={{ width: "100%", pointer: "cursor" }} onClick={() => dispatch(channel.actions.connectAccount)}>
                                                <IconButton aria-label="delete">
                                                    <AddIcon />
                                                </IconButton>
                                                Add more Stores
                                            </div>}
                                        </div>
                                        :
                                        <Grid container justifyContent="center" style={{ height: "100%", width: "95%", margin: "20px auto" }}>
                                            <Grid xs={12} style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "15px", marginTop: "15px" }}>
                                                <img src={channel.icon} style={{ marginRight: 8, height: 20, width: 20 }} alt={channel.title} />
                                            </Grid>
                                            <Grid xs={12} style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "18px" }}>
                                                <p><b>{channel.title} Account</b></p>
                                            </Grid>
                                            <Grid xs={12} style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                                                <p style={{ fontSize: "13px", color: "rgba(0, 0, 0, 0.5)" }}>
                                                    Pixis Analytics works best with real data. <br />
                                                    <span style={{ textAlign: "center", width: "100%", display: "inline-block" }}>Connect your account and data sources.</span>
                                                </p>
                                            </Grid>
                                            <Grid xs={12} style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "25px" }}>
                                                {(user.role === "admin" || user.role === "superadmin") ?
                                                    <Button
                                                        style={{ backgroundColor: "#0869FB", color: "white", padding: "5px 15px" }}
                                                        onClick={() => dispatch({ ...channel.actions.connectAccount, payload: { redirect_url: "managesources" } })}
                                                    >
                                                        Connect an account
                                                    </Button>
                                                    :
                                                    <p style={{ fontSize: "13px", color: "rgba(0, 0, 0, 0.5)" }}>
                                                        <b>Note: No data sources have been connected yet.</b> <br />
                                                        <span style={{ textAlign: "center", width: "100%", display: "inline-block" }}><b>Please contact your Admin.</b></span>
                                                    </p>
                                                }
                                            </Grid>
                                            <Grid xs={12} style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "25px" }}>
                                                <p style={{ color: "#0869FB" }}><Link style={{ textDecoration: "underline" }} onClick={() => history.push("/personalized-demo")}>Get Help</Link></p>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                        </Stack>
                    </Card>
                </Grid>
                <Grid item xs={6} style={{ height: "calc(100vh - 305px)" }}>
                    <Card sx={{ boxShadow: 3, height: "100%", display: "flex", flexDirection: "column" }} >
                        <TextField
                            placeholder='Data Source'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        <SearchIcon sx={{ marginLeft: '15px' }} />
                                    </InputAdornment>
                                ),
                            }}
                            type="search"
                            variant='standard'
                            onChange={event => fetchFilteredList(event.target.value)}
                            sx={{ width: '100%', ".MuiInput-root": { height: '48px' }, ".MuiInput-root:before": { borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }, ".MuiInput-input": { marginLeft: '15px', marginTop: '2px' } }}
                        />
                        <List
                            sx={{ width: "100%", bgcolor: "background.paper", textAlign: "left", marginTop: '10px', minHeight: "70px" }}
                            component="nav"
                            subheader={
                                <div style={{ paddingLeft: "25px" }} component="div">
                                    <h5 className="inter bold">ASSIGNED</h5>
                                </div>
                            }>
                            {selectedAccountData && isAnyChannelSelected &&
                                <ListItemButton style={{ width: "100%", padding: "10px 25px" }}
                                    onClick={() => handleCheckboxSelection(null)}>
                                    <FormControlLabel
                                        style={{ marginLeft: 0, marginRight: '10px' }}
                                        sx={{ marginLeft: 0, marginRight: '10px' }}
                                        control={<Checkbox
                                            style={{ padding: 0, marginRight: '10px' }}
                                            icon={<CircleUnchecked />}
                                            checkedIcon={<CircleCheckedFilled />}
                                            checked={true}
                                        // onChange={event => handleCheckboxSelection(null)}
                                        />}
                                    />
                                    <TitleSubtitle title={selectedAccountData.title} subtitle={selectedAccountData.subtitle} />
                                </ListItemButton>
                            }
                            {!selectedAccountData && (
                                isChannelAuthenticated(channel) ?
                                    <Typography variant="body2" style={{ textAlign: "center" }}>Please select from the list below</Typography>
                                    :
                                    <Typography variant="body2" style={{ textAlign: "center" }}>Please connect channel</Typography>
                            )}
                        </List>
                        <div style={{ display: "flex", flexDirection: "column", height: "calc(100% - 150px)" }}>
                            <div style={{ padding: "5px 15px 5px 25px", width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} component="div">
                                <h5 className="inter bold" style={{ margin: "auto 0px" }}>AVAILABLE</h5>
                                {(isAnyChannelSelected && Math.ceil((filteredAccountList.length - 1) / rowsPerPage) > 1) &&
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <IconButton sx={{ color: "black" }} style={{ padding: "5px" }}
                                            onClick={() => setPage(prev => prev - 1)} disabled={page === 0}
                                        >
                                            <ArrowLeftIcon height="20px" width="20px" />
                                        </IconButton>
                                        <IconButton sx={{ color: "black" }} style={{ padding: "5px" }}
                                            onClick={() => { transformMixPanelData(EVENTS.pagination_gads_account, {}); setPage(prev => prev + 1) }} disabled={(page + 1) === (Math.ceil((filteredAccountList.length - 1) / rowsPerPage))}
                                        >
                                            <ArrowRightIcon height="20px" width="20px" />
                                        </IconButton>
                                    </div>
                                }
                            </div>
                            <List
                                sx={{ padding: "0px 15px", width: "100%", bgcolor: "background.paper", textAlign: "left", overflowY: "auto", display: "flex", flexWrap: "wrap" }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                {isAnyChannelSelected &&
                                    filteredAccountList.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                                        .filter(item => getAccountId(item) !== getAccountId(selectedAccountData))
                                        .map((item, index) =>
                                            <ListItemButton key={index + getAccountId(item)} onClick={() => handleCheckboxSelection(item)}
                                                style={{ width: "45%", maxWidth: "45%", padding: "10px 15px", border: "1px solid #f0f0f0", borderRadius: "12px", margin: "10px" }}>
                                                <Checkbox
                                                    style={{ marginRight: "10px" }}
                                                    sx={{ marginRight: '10px' }}
                                                    icon={<CircleUnchecked />}
                                                    checkedIcon={<CircleCheckedFilled />}
                                                // onChange={event => handleCheckboxSelection(item)}
                                                />
                                                <TitleSubtitle style={{ marginLeft: "15px" }} title={item.title} subtitle={item.subtitle} />
                                            </ListItemButton>
                                        )}
                                {(!accountData || !accountData.accountList || accountData.accountList.length === 0) && isChannelAuthenticated(channel) &&
                                    <div style={{ width: "100%", height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Loader />
                                    </div>
                                }
                            </List>
                        </div>
                    </Card>
                </Grid>
            </Grid>
            {
                selectedAccountTypes.length > 0 &&
                <Button style={{ float: "right", marginTop: "30px", backgroundColor: "#0769FB" }} variant="contained" color="primary" onClick={() => { transformMixPanelData(EVENTS.generate_report, {}); history.push(defaultReportId ? `/dashboard/${defaultReportId}` : allReports.length ? `/dashboard/${allReports[0]?.id}` : `/dashboard`) }}>Generate Reports</Button>
            }
            <SelectingChannelErrorModal modal={errorModal} setModal={setErrorModal} />
            <RemoveChannelAccountModal modal={modal} setModal={setModal} channel={channel} onSubmit={() => dispatch({ ...channel.actions.disconnectAccount, payload: disconnectAccountPayload.current })} />
        </div>
    );
}

const mapStateToProps = state => ({
    allReports: state.report.allReports,
    defaultReportId: state.report.defaultReportId,
    selectedAccounts: state.channel.selectedAccounts,
    authenticatedAccounts: state.channel.authenticatedAccounts,
    user: state.user.user,
    isConnectingShopify: state[ChannelType.ShopifyAds].isConnecting,
    isConnectingAppsflyer: state[ChannelType.Appsflyer].isConnecting,
    isConnectingAdjust: state[ChannelType.Adjust].isConnecting
});

const mapDispatchToProps = dispatch => ({
    checkAuth: () => dispatch(checkAuth()),
    getUser: () => dispatch(getUser()),
    getAllReports: () => dispatch(getAllReports()),
    getDefaultReport: (_) => dispatch(getDefaultReport(_)),
    closeShopify: () => dispatch(closeConnecting()),
    sendBingCode: (_) => dispatch(sendBingCode(_)),
    sendLinkedlnCode: (_) => dispatch(sendLinkedlnCode(_)),
    closeAppsflyer: (_) => dispatch(closeConnectingAppsflyer(_)),
    closeAdjust: (_) => dispatch(closeConnectingAdjust(_))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageDataSources);

import React, { useEffect, useState } from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";
import LineChartLogo from "../../Assets/Icons/line.svg";
import BarChartLogo from "../../Assets/Icons/bar.svg";
import BarChart3DLogo from "../../Assets/Icons/bar3d.svg";
import PieChartLogo from "../../Assets/Icons/pie.svg";
import PieChart3DLogo from "../../Assets/Icons/pie3d.svg";
import DonutChartLogo from "../../Assets/Icons/donut.svg";
import DonutChart3DLogo from "../../Assets/Icons/donut3d.svg";
import FunnelChartLogo from "../../Assets/Icons/funnel.svg";
import KPIChartLogo from "../../Assets/Icons/kpi.svg";
import LinebarChartLogo from "../../Assets/Icons/linebar.svg";
import StackbarChartLogo from "../../Assets/Icons/stackbar.svg";
import StackbarChart3DLogo from "../../Assets/Icons/stackbar3d.svg";
import TableChartLogo from "../../Assets/Icons/table.svg";
import ColumnChartLogo from "../../Assets/Icons/column.svg";
import BubbleChartLogo from "../../Assets/Icons/bubble-chart.svg";
import AreaChartLogo from "../../Assets/Icons/area-chart.svg";
import WordCloudChartLogo from "../../Assets/Icons/word-cloud.svg";
import ScatterChartLogo from "../../Assets/Icons/scatter.svg";
import TreeMapChartLogo from "../../Assets/Icons/tree-map.svg";
import HeatMapChartLogo from "../../Assets/Icons/heatmap.svg";
import WaterfallChartLogo from "../../Assets/Icons/waterfall.svg";
import GeoMapChartLogo from "../../Assets/Icons/geo.svg";
import { DimensionsLength, MetricsLength } from "../Charts/ChartUtils";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const ChartVariants = {
  "2D": {
    name: "2D",
  },
  "3D": {
    name: "3D",
  }
}

const allChartTypes = {
  LINE: { type: "LINE", name: "Line", image: LineChartLogo, compatible: 1, variant: "2D" },
  BAR: { type: "BAR", name: "Bar", image: BarChartLogo, compatible: 1, variant: "2D" },
  BAR3D: { type: "BAR3D", name: "Bar (3D)", image: BarChart3DLogo, compatible: 1, variant: "3D" },
  PIE: { type: "PIE", name: "Pie", image: PieChartLogo, compatible: 2, variant: "2D" },
  PIE3D: { type: "PIE3D", name: "Pie (3D)", image: PieChart3DLogo, compatible: 2, variant: "3D" },
  DONUT: { type: "DONUT", name: "Donut", image: DonutChartLogo, compatible: 2, variant: "2D" },
  DONUT3D: { type: "DONUT3D", name: "Donut (3D)", image: DonutChart3DLogo, compatible: 2, variant: "3D" },
  FUNNEL: { type: "FUNNEL", name: "Funnel", image: FunnelChartLogo, compatible: 2, variant: "2D" },
  KPI: { type: "KPI", name: "KPI", image: KPIChartLogo, compatible: 4, variant: "2D" },
  LINEBAR: { type: "LINEBAR", name: "Line bar", image: LinebarChartLogo, compatible: 1, variant: "2D" },
  STACKBAR: { type: "STACKBAR", name: "Stack bar", image: StackbarChartLogo, compatible: 1, variant: "2D" },
  STACKBAR3D: { type: "STACKBAR3D", name: "Stacked (3D)", image: StackbarChart3DLogo, compatible: 1, variant: "3D" },
  TABLE: { type: "TABLE", name: "Table", image: TableChartLogo, compatible: 3, variant: "2D" },
  COLUMN: { type: "COLUMN", name: "Column", image: ColumnChartLogo, compatible: 3, variant: "2D" },
  BUBBLE: { type: "BUBBLE", name: "Bubble", image: BubbleChartLogo, compatible: 3, variant: "2D" },
  AREA: { type: "AREA", name: "Area", image: AreaChartLogo, compatible: 1, variant: "2D" },
  SCATTER: { type: "SCATTER", name: "Scatter", image: ScatterChartLogo, compatible: 3, variant: "2D" },
  WORDCLOUD: { type: "WORDCLOUD", name: "Wordcloud", image: WordCloudChartLogo, compatible: 3, variant: "2D" },
  TREEMAP: { type: "TREEMAP", name: "Treemap", image: TreeMapChartLogo, compatible: 3, variant: "2D" },
  HEATMAP: { type: "HEATMAP", name: "Heatmap", image: HeatMapChartLogo, compatible: 3, variant: "2D" },
  // WATERFALL: { type: "WATERFALL", name: "Waterfall", image: WaterfallChartLogo, compatible: 3, variant: "2D" },
  GEO: { type: "GEO", name: "Geo", image: GeoMapChartLogo, compatible: 3, variant: "2D" },
};

const normalBorder = "1px solid #EFEFEF";
const selectedBorder = "4px solid rgba(8, 105, 251, 0.4)";

export default function SwitchChart({
  chartMetrics,
  chartDimensions,
  chartType,
  setChartType,
  newChart,
}) {
  const convertableDimensions = (chartType) => {
    return (
      newChart ||
      chartDimensions.filter((dim) => dim)?.length <=
      DimensionsLength[chartType]
    );
  };
  const convertableMetrics = (chartType) => {
    return (
      newChart ||
      chartMetrics.filter((metric) => metric)?.length <=
      MetricsLength[chartType]
    );
  };

  const [chartVariant, setChartVariant] = useState(chartType.endsWith("3D") ? "3D" : "2D");

  const [specificCharts, setSpecificCharts] = useState(newChart
    ? Object.values(allChartTypes).filter((chart) => chart.variant === chartVariant)
    : Object.values(allChartTypes).filter(
      (chart) => chart.compatible === allChartTypes[chartType].compatible
    ));

  const chartIndex = Math.floor(
    specificCharts.findIndex((chart) => chart.type === chartType) / 9
  );
  const [{ start, end }, setPagination] = useState(
    chartIndex === -1
      ? { start: 0, end: 8 }
      : { start: chartIndex * 9, end: chartIndex * 9 + 8 }
  );

  const navigate = (type) => {
    if (type === "Next") {
      setPagination({ start: start + 9, end: end + 9 });
    } else {
      setPagination({ start: start - 9, end: end - 9 });
    }
  };

  return (
    <div
      // className="smooth-shadow"
      style={{
        border: "2px solid #EAEAEC",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "16px 5px",
        borderRadius: "10px",
        // boxShadow: "12px 12px 48px 0px #red",
      }}
    >
      <div className="displayRowSpaced">
        <div className="displayRowEven" style={{ border: "1px solid #EAEAEC", borderRadius: "6px", padding: "4px", width: "fit-content" }}>
          {newChart && Object.values(ChartVariants).map(variant => {
            return (
              <Tooltip placement="top" style={{ cursor: "pointer" }}
                title={variant.disabled ? `User does not have the permission to view ${variant.name}` : ""}
              >
                <Typography
                  sx={{
                    width: `max-content`, padding: "4px 8px", borderRadius: "6px", textAlign: "center", cursor: variant.disabled ? "default" : "pointer",
                    color: variant.disabled ? "#C0C1C7" : "#585969", fontSize: "14px", textTransform: "uppercase"
                  }}
                  style={chartVariant === variant.name ? { background: "#0869FB", color: "white" } : {}}
                  onClick={() => {
                    setChartVariant(variant.name);
                    setSpecificCharts(Object.values(allChartTypes).filter((chart) => chart.variant === variant.name));
                    setPagination({ start: 0, end: 8 });
                  }}
                >
                  {variant.name}
                </Typography>
              </Tooltip>
            )
          })
          }
        </div>
        {specificCharts.length > 9 && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              sx={{ color: "black" }}
              style={{ background: "#F8F8F8", margin: "5px", padding: "5px" }}
              onClick={() => navigate("Back")}
              disabled={start === 0}
            >
              <ArrowLeftIcon />
            </IconButton>
            <IconButton
              sx={{ color: "black" }}
              style={{ background: "#F8F8F8", margin: "5px", padding: "5px" }}
              onClick={() => navigate("Next")}
              disabled={specificCharts.length <= end + 1}
            >
              <ArrowRightIcon />
            </IconButton>
          </div>
        )}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {specificCharts
          .filter((chart, index) => index >= start && index <= end)
          .map((chartImage, index) => (
            <Tooltip
              title={
                DimensionsLength[chartImage.type] &&
                  !convertableDimensions(chartImage.type)
                  ? `Unable to convert for dimensions greater than ${DimensionsLength[chartImage.type]}`
                  : MetricsLength[chartImage.type] &&
                    !convertableMetrics(chartImage.type)
                    ? `Unable to convert for metrics greater than ${MetricsLength[chartImage.type]}`
                    : ""
              }
              placement="bottom"
            >
              <div
                key={index}
                style={
                  (DimensionsLength[chartImage.type] &&
                    !convertableDimensions(chartImage.type)) ||
                    (MetricsLength[chartImage.type] &&
                      !convertableMetrics(chartImage.type))
                    ? {
                      margin: "10px 5px",
                      opacity: "0.5",
                      cursor: "default",
                      width: "calc(33% - 10px)",
                    }
                    : { margin: "10px 5px", width: "calc(33% - 10px)" }
                }
              >
                <h5 className="inter bold" style={{ marginBottom: "5px" }}>
                  {chartImage.name}
                </h5>
                <div
                  style={{
                    width: "100%",
                    height: "80px",
                    backgroundColor: "rgba(200, 200, 200, 0.2)",
                    border:
                      chartType === chartImage.type
                        ? selectedBorder
                        : normalBorder,
                    borderRadius: "5px",
                    cursor:
                      (DimensionsLength[chartImage.type] && !convertableDimensions(chartImage.type)) ||
                        (MetricsLength[chartImage.type] && !convertableMetrics(chartImage.type))
                        ? "default"
                        : "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    if (
                      !(
                        (DimensionsLength[chartImage.type] &&
                          !convertableDimensions(chartImage.type)) ||
                        (MetricsLength[chartImage.type] &&
                          !convertableMetrics(chartImage.type))
                      )
                    )
                      setChartType(chartImage.type);
                  }}
                >
                  <img
                    style={{ width: "80%", maxHeight: "60px" }}
                    src={chartImage.image}
                    alt={chartImage.name}
                  />
                </div>
              </div>
            </Tooltip>
          ))}
      </div>
    </div>
  );
}
